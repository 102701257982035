/*------- 6. Product style  --------*/
.product-area {
  position: relative;
  .row {
    &.three-column {
      .col-xl-3 {
        flex: 1 0 33.3333%;

        max-width: 33.3333%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
    &.five-column {
      .col-xl-3 {
        flex: 1 0 20%;

        max-width: 20%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
  }

  &--style2 {
    .ht-swiper-button-nav {
      font-size: 28px;
      line-height: 1;

      position: absolute;
      top: 15px;
      right: 0;

      display: inline-block;

      width: 35px;
      height: 35px;
      padding: 0;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      i {
        padding-top: 3px;
      }
      @media #{$xs-layout} {
        margin-top: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;
      }
      &.swiper-button-prev {
        right: 40px;
        left: auto;
        @media #{$xs-layout} {
          margin-right: 10px;
        }
      }

      @media #{$xs-layout} {
        position: static;

        text-align: center;
      }
    }
  }
}

.product-tab-list {
  justify-content: center;
  &.pt-30,
  &.pt-55 {
    @media #{$xs-layout} {
      padding: 10px 0 30px;
    }
  }
  @media #{$md-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 20px 0 30px;
    }
  }
  @media #{$xs-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 10px 0 10px;
    }
  }
  a {
    margin: 0 11px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 11px 5px;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;

      transition: all 0.3s ease 0s;

      color: #555;
      &:hover {
        color: #000;
      }
    }
    &.active h4 {
      color: #000;
    }
  }

  &--style2 {
    .nav-item {
      a {
        margin: 0;
        margin-left: 20px;
        padding: 0;

        text-transform: uppercase;
        &.active {
          h4 {
            color: #fed700;
          }
        }
        h4 {
          font-weight: 700;

          color: #5f5f5f;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }
    }

    @media #{$md-layout,
    $xs-layout} {
      margin-top: 10px;
    }
  }
}

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;
    box-shadow: $product-shadow;
    border-radius: $card-border-radius;
    overflow: hidden;

    // Images are 270*270 in default view, so fill this area before image is loaded
    //min-height: 270px;
    //min-width: 270px;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: $theme-color-secondary;
        }
        &.purple {
          background-color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;

      visibility: hidden;

      transition-duration: 0.7s;
      transform: translate(-50%, 20px);

      opacity: 0;
    }
    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 50%;

      display: flex;
      align-items: center;
      flex-wrap: wrap;

      width: 100%;

      transform: translateX(-50%);
      > div {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;

        height: 48px;

        transition: all 0.2s ease;
        transform: translateY(20px);

        opacity: 0;
        background-color: $theme-btn-cta;
         &:hover {
           background-color: lighten($theme-btn-cta, 5%);
         }
      }
      .pro-wishlist {
        width: 48px;

        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-top-left-radius: $btn-border-radius;
        border-bottom-left-radius: $btn-border-radius;
      }
      .pro-cart {
        font-size: 14px;

        width: calc(100% - 48px - 48px);

        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        margin: 0;

        transition-delay: 0.2s;

        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-top-right-radius: $btn-border-radius;
        border-bottom-right-radius: $btn-border-radius;
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;

          display: block;

          width: 100%;
          height: 48px;

          text-align: center;
          text-transform: capitalize;

          color: $theme-btn-cta-text;
          border: none;
          background: none;
          border-radius: inherit;
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      margin: 0;
    }
    .product-rating {
      margin: 3px 0 3px;
      text-align: left;
      i {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      text-align: left;
      span {
        font-size: 15px;
        font-weight: 500;

        position: relative;

        margin-right: 6px;

        color: #1d71b8;
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #8e8e8e;
          white-space: nowrap;
        }
        &.advisory-text {
          font-size: 13px;
          font-weight: 400;
          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-top: 6px;
      margin-left: 0;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1;

    margin: 0 0 0;

    color: #010101;
  }
  .product-details-price {
    display: flex;
    align-items: center;

    margin: 15px 0 4px;
    span {
      font-size: 24px;

      color: #fe5252;
      &.old {
        font-size: 18px;

        margin-left: 20px;

        text-decoration: line-through;

        color: #333;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;

    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;

      margin-right: 39px;
      &:before {
        position: absolute;
        top: 4px;
        right: -19px;

        display: none;

        width: 2px;
        height: 15px;

        content: "";

        background-color: #d1d1d1;
      }
      i {
        font-size: 17px;

        margin-right: 5px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;

        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 28px;

    margin: 0;

    color: #333;
    white-space: pre-line;
  }
  .pro-details-list {
    margin: 34px 0 20px;
    padding: 25px 0 0;

    border-top: 1px solid #e5e5e5;
    ul {
      li {
        margin: 0 0 5px;

        color: #333;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-size {
      span {
        color: #333;
        font-size: 15px;
        font-weight: 400;

        display: block;

        margin: 0 0 10px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;

            margin: 0 2px 0 0;

            list-style: outside none none;
            a {
              font-size: 12px;
              line-height: 1;

              display: inline-block;

              padding: 6px 9px 7px;

              text-transform: uppercase;

              color: #000;
              background-color: #f1f2f6;
              &:hover {
                color: #fff;
                background-color: $theme-color;
              }
            }
          }
        }
        &--single {
          font-size: 12px;
          line-height: 1;
          border-radius: $btn-border-radius;

          position: relative;

          display: inline-block;

          margin: 5px 5px 0 0;

          text-transform: uppercase;

          color: #000;
          background-color: #f1f2f6;
          &:hover {
            color: #fff;
            background-color: $theme-color;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .size-name {
                color: $white;
                background-color: $theme-color;
              }
            }
          }
          .size-name {
            font-size: 14px;
            font-weight: 400;

            width: 100%;
            margin-bottom: 0;
            padding: 16px 35px 17px;
            border-radius: $btn-border-radius;
          }
        }
      }
    }
  }
  .pro-details-quality {
    display: flex;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 34px;
    .cart-plus-minus {
      position: relative;

      display: inline-block;
      overflow: hidden;

      width: 80px;
      height: 60px;
      padding: 0;

      border: 1px solid #e8e8e8;
      border-radius: $btn-border-radius;
      .qtybutton {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        position: absolute;

        float: inherit;

        width: 24px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #8f8f8f;
        border: none;
        background: none;
      }
      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 60px;
      }
      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 60px;
      }
      input.cart-plus-minus-box {
        font-size: 14px;

        float: left;

        width: 80px;
        height: 60px;
        margin: 0;
        padding: 0;

        text-align: center;

        color: #8f8f8f;
        border: medium none;
        background: transparent none repeat scroll 0 0;
      }
    }
    .pro-details-cart {
      margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        margin: 0 17px 0 10px;
      }
      a,
      button {
        border-radius: $btn-border-radius;
        font-weight: bold;
        line-height: 1;

        z-index: 99;

        display: inline-block;

        padding: 23px 38px 23px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        background: none;
        background-color: $theme-btn;
        @media #{$xs-layout} {
          padding: 23px 12px 23px;
        }
        @media #{$md-layout} {
          padding: 22px 22px 22px;
        }
        &:hover {
          border: none;
          border-radius: $btn-border-radius;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .pro-details-wishlist {
    a,
    button {
      font-size: 22px;

      color: #000;
      border: none;
      background: none;
      &:hover,
      &.active {
        color: $theme-color;
      }
    }
  }
  .pro-details-compare {
    a,
    button {
      font-size: 22px;

      margin-left: 25px;

      color: #000;
      border: none;
      background: none;
      @media #{$xs-layout} {
        margin-left: 10px;
      }
      &:hover,
      &.active {
        color: $theme-color;
      }
    }
  }
  .pro-details-meta {
    display: flex;

    margin: 0 0 10px;
    span {
      font-size: 15px;

      margin-right: 5px;

      color: #676767;
    }
    ul {
      li {
        display: inline-block;

        margin: 0 10px 0 0;
        a {
          font-size: 15px;

          color: #676767;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 28px;

          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
}

.pro-details-type {
  .title {
    color: #333;
    font-size: 15px;
    font-weight: 400;

    display: block;

    margin: 0 0 10px;
  }
}

.product-tab-list-2 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
  }
  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      border-bottom: 2px solid transparent;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      h4 {
        color: #000000;
        border-bottom: 2px solid #000000;
      }
    }
  }
}

.product-wrap-2 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
      border-radius: $card-border-radius;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      top: 20px;
      right: 20px;
      &.pink {
        color: #FFF;
        background-color: $theme-color-secondary;
      }
      &.red {
        color: #ff3d2a;
      }
      &.purple {
        color: $theme-color;
      }
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      top: 50%;

      display: flex;
      justify-content: center;

      width: 100%;

      transform: translateY(-50%);
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;
        &:hover,
        &.active {
          background-color: $theme-color-secondary;
        }
      }
      &.product-action-2-red {
        a {
          background-color: #df262b;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;
        &.pink {
          background-color: $theme-color-secondary;
          color: #FFF;
        }
        &.purple {
          color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      max-width: 80%;
      h3 {
        line-height: 1;
        white-space: pre-wrap;
        margin: 0 0 6px;
        a {
          font-size: 16px;

          color: #000000;
          &:hover {
            color: $theme-color;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #000000;
          &.old {
            text-decoration: line-through;

            color: $theme-color-secondary;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }

      &.title-price-wrap-2-white {
        h3 {
          a {
            color: #fff;
            &:hover {
              color: $theme-color;
            }
          }
        }
        .price-2 {
          span {
            color: #fff;
            &.old {
              text-decoration: line-through;

              color: $theme-color-secondary;
            }
          }
        }
      }
    }
    .pro-wishlist-2 {
      a,
      button {
        font-size: 28px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
  &.pro-glaucous-color {
    .product-img {
      span.glaucous {
        color: #3d6882;
      }
      .product-action-2 {
        a,
        button {
          background-color: #3d6882;
          &:hover,
          &.active {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover {
        color: #3d6882;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #3d6882;
        }
        .price-2 {
          span.old {
            color: #3d6882;
          }
        }
      }
    }
  }

  &.pro-puce-color {
    .product-img {
      span.puce {
        color: #de3e3e;
      }
      .product-action-2 {
        a,
        button {
          background-color: #de3e3e;
          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover,
      .pro-wishlist-2 button:hover {
        color: #de3e3e;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #de3e3e;
        }
        .price-2 {
          span.old {
            color: #de3e3e;
          }
        }
      }
    }
  }
}

.view-more {
  display: block;

  width: 100%;

  text-align: center;
  a {
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding-bottom: 2px;

    text-align: center;

    color: #555;
    border-bottom: 1px solid #cccccc;
    &:hover {
      color: $theme-color;
      border-bottom: 1px solid $theme-color;
    }
  }
  @media #{$xs-layout} {
    &.mt-20 {
      margin-top: 0;
    }
  }

  &.round-btn {
    a {
      font-size: 16px;
      line-height: 1;

      display: inline-block;

      padding: 16px 40px 16px 40px;

      text-transform: capitalize;

      color: #fff;
      border-radius: 34px 34px 34px 34px;
      background-color: #c61a32;

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}

.funfact-area {
  position: relative;
}

.product-quickview-modal-wrapper {
  .modal-dialog {
    margin: 5% auto;
    padding: 0;
  }
}

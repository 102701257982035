.ant-btn {
  &.theme-primary {
    background: $theme-color;
    border-color: $theme-color;

    &:hover {
      background: transparentize($theme-color, 0.2);
      border-color: transparentize($theme-color, 0.2);
    }
  }

  span {
    transition: none;
    vertical-align: baseline;
  }
}

.ant-radio-button-wrapper {
  span {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: inherit;

    img {
      width: 100px;
      max-height: 35px;
    }
  }
}

.ant-table-thead {
  tr th {
    background: transparentize($theme-color, 0.8);
    border-bottom: 1px solid transparentize($theme-color, 0.8);
  }
}

// In message, make sure icon is in middle vertically to match up with text
.ant-message .anticon {
  top: 0px;
  vertical-align: middle;
}

.ant-picker {
  width: 100%;
}

.ant-pagination {
  text-align: center;
  margin-top: 30px;

  li {
    width: 42px;
    height: 42px;
    line-height: 42px;

    border-radius: $btn-border-radius;

    margin-right: 1rem;
    margin-bottom: .75rem;
  }
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:focus-visible {
  border-color: $theme-color;
}

.ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-link-icon,
.ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-link-icon {
  color: $theme-color;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $theme-color;
  border-color: $theme-color;
}

.ant-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    font-size: 15px;
    display: flex;
    flex-direction: row;
  }

  // All clickable elements
  span:last-child a, a {
    color: #40a9ff;
  }

  // Last (non-clickable) element
  > span:last-child {
    color: rgba(0, 0, 0, 0.5);
  }

  .anticon-home {
    font-size: 18px;
    vertical-align: 0.1em;
  }
}

.ant-form-item-explain {
  min-height: 0px;
}

.ant-form-item-children-icon {
  span {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.anticon {
  vertical-align: middle !important;
}
